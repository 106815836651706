<template>
    <div>
        <div class="py-2 px-1" v-if="this.ShowMDAApproval">
            <p class="approve-reject-label px-3 pt-2 pb-1">Approve or Reject This Production Vendor</p>
            <p class="approve-reject-content px-3 py-2">
                When approved, this request will be sent to the Vendor validation
                Team.
            </p>
            <div class="approve-reject-content px-3 pt-2" v-show="Request.Status == 'MDA Manager Approval'">
                <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group id="checkbox-group-2" v-model="formdata.requiredaccountingapproval"
                    :aria-describedby="ariaDescribedby" name="flavour-2">
                    <b-form-checkbox value="yes">Route to Accounting Approval?</b-form-checkbox>
                </b-form-checkbox-group>
                </b-form-group>
            </div>
            <div class="text-right pb-3 pr-3" style="width: 100%">
                <button class="btn custom-button btn-primary" @click="Approve()">
                Approve
                </button>
            </div>
        </div>
        <div class="bg-white shadow-sm rounded py-2 px-1" v-if="this.ShowPendingorCompletediv">
            <h6 class="text-center">
                <strong>Vendor Validation Complete or Pending</strong>
            </h6>
            <div class="toggle-area d-flex justify-content-center rejection-comments-new">
                <toggle-switch :options="myOptions" :disabled="false" @change="updateMap($event.value)" :name="name"
                    :group="switchGroup" />
            </div>
            <div class="rejection-comments-new" v-if="VendorPending">
                <label>Pending Verification Documents</label>
                <OmniSelect placeholder="Pending Verification Documents" name="Media" :showLabel="false"
                    label="Select Media" :options="MissingDocs" v-model="VendorValidation.MissingVendorDocs" />
            </div>
            <div class="rejection-comments-new" v-if="VendorComplete">
                <label>Vendor Validation Documents</label>
                <vue-dropzone v-on:vdropzone-mounted="mountedAttachmentDropzone" :duplicateCheck="true"
                    ref="dropzoneAttachment" id="dropzoneAttachment" :useCustomSlot="true" class="customdropzone"
                    :include-styling="false" :options="dropzoneOptions"
                    v-on:vdropzone-file-added="AttachmentUploadedToDropzone"
                    v-on:vdropzone-removed-file="AttachmentRemovedFromDropzone">
                    <div class="dropzone-custom-content">
                        <p class="Drag-and-drop-file-h d-flex justify-content-between">
                            <b-img />
                            <span class="m-0">Drag and drop file here or</span>
                            <span class="draganddropspan"> browse </span>
                        </p>
                    </div>
                </vue-dropzone>
            </div>
            <div class="attempt-history" v-if="VendorValidationRequest && VendorValidationRequest.AttemptCounter > 0">
                    <b-img v-b-tooltip.hover.v-dark.html="content" @click="ShowAttemptHistory()" v-b-tooltip.hover title="View Attempt History" :src="historyIcon"
                    class="btn btn-transparent Custom-Popup" />
            </div>
            <div class="jumbotron mx-2 mt-5 mb-3 py-3 px-0 level-container position-relative">
                <label class="attempts attempts1" v-if="VendorValidationRequest">Attempt
                    {{ VendorValidationRequest.AttemptCounter == 3 ? VendorValidationRequest.AttemptCounter : VendorValidationRequest.AttemptCounter + 1}}
                </label>
                <label class="attempts attempts1" v-else>Attempt 1</label>
                <div class="rejection-comments-new">
                    <label>Date of Vendor Contact</label>
                    <b-form-datepicker inputType="date" id="calendar-picker"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        :max="new Date()" v-model="VendorValidation.DateTime">
                    </b-form-datepicker>
                    <!-- <b-form-input  style="width:300px;" autocomplete="off" id="entitynameperagreement" class="omniipclass"  v-model="datetime" placeholder="DateTime" :maxlength="10"></b-form-input>                                 -->
                </div>
                <div class="rejection-comments-new">
                    <label>Method for reaching out </label>
                    <OmniSelect placeholder="Select reaching method" name="Media" :showLabel="false"
                        label="Select Media" :options="ReachingOptions" v-model="VendorValidation.ReachingMethod" />
                </div>
                <div class="rejection-comments-new">
                    <label>Comments </label>
                    <b-textarea rows="5" v-model="VendorValidation.ValidationComments"></b-textarea>
                </div>
            </div>
            <div class="rejection-comments-new">
                <label>Vendor Contact Name</label>
                <b-form-input autocomplete="off" id="Vendor Contact Name" class="omniipclass"
                    v-model="VendorValidation.VendorContactName" placeholder="Enter Vendor Contact Name" :maxlength="99">
                </b-form-input>
            </div>
            <div class="rejection-comments-new">
                <label>Vendor Title</label>
                <b-form-input autocomplete="off" id="Vendor Title" class="omniipclass"
                    v-model="VendorValidation.VendorTitle" placeholder="Enter Vendor Title" :maxlength="99">
                </b-form-input>
            </div>
            <div class="rejection-comments-new">
                <label>Vendor Phone</label>
                <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '1-###-###-####']"
                    style="margin-top:1px;" class="form-control" @blur="SetDirty(VendorValidation.VendorPhoneNumber)"
                    @focus="ResetDirty(VendorValidation.VendorPhoneNumber)" autocomplete="off" id="InfoFromPhone" :maxlength="14"
                    v-model="VendorValidation.VendorPhoneNumber"  placeholder="Enter Vendor Phone"></the-mask>
                <div class="error-message-format"
                    v-if="$v.VendorValidation.VendorPhoneNumber.$dirty && !$v.VendorValidation.VendorPhoneNumber.required">
                    Required field
                </div>
            </div>
            <div class="rejection-comments-new">
                <label>Vendor Email</label>
                <b-form-input type="email" @focus="ResetDirty('VendorEmail')"
                    @blur="SetDirty('VendorEmail')" autocomplete="off"
                    placeholder="Vendor Email" v-model="VendorValidation.VendorEmail"
                    ref="email"></b-form-input>
                <div class="error-message-format" v-if="$v.VendorValidation.VendorEmail.$dirty">
                    <span v-show="!$v.VendorValidation.VendorEmail.required">
                        Required field
                    </span>
                    <span v-show="!$v.VendorValidation.VendorEmail.email">
                        Please enter a valid email address (eg. test@finsys.com)
                    </span>
                </div>
            </div>
            <div class="rejection-comments-new">
                <b-form-group label="" class="mb-1 timeonly-text">
                    <label>Did the vendor confirm the payment information? </label>
                    <b-form-radio-group style="margin-left: 10px" class="omniipclass" id="ShowPaymentInfo1"
                        v-model="VendorValidation.ConfirmPaymentInfo" @change="ShowPaymentInfo" name="ShowPaymentInfo">
                        <b-form-radio value="true">YES</b-form-radio>
                        <b-form-radio value="false">NO</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="rejection-comments-new">
                <b-form-group label="" class="mb-1 timeonly-text">
                    <label style="line-height: revert"> Did the vendor provide an ACH/Wire info sheet if paying by
                        ACH/Wire?
                    </label>
                    <b-form-radio-group style="margin-left: 10px" @change="ShowACHwireInfo" class="omniipclass"
                        id="ShowACHwireInfo1" v-model="VendorValidation.VendorACHInfo" name="ShowACHwireInfo1">
                        <b-form-radio value="true">YES</b-form-radio>
                        <b-form-radio value="false">NO</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="text-right" style="width: 100%; padding-top: 10px">
                <button v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-primary" @click="SubmitVendorValidation()"
                    :disabled="this.$v.VendorValidation.$invalid || (this.VendorPending && this.VendorValidation.AttemptCounter >= 3)">
                    SUBMIT
                </button>
                <button v-else class="btn custom-button btn-primary" disabled="disabled"
                    title="The request is already approved or rejected.">
                    SUBMIT
                </button>
            </div>
        </div>
        <div class="bg-white shadow-sm rounded py-2 my-2 px-3">
            <label class="">Rejection Comments</label>
            <b-textarea class="rejection-comments-new m-custom" placeholder="Enter Rejection Comments"
                v-model="RejectionComments" v-bind:class="{
                    errorborder: !$v.RejectionComments.required && $v.RejectionComments.$dirty,
                    noterrorborder: $v.RejectionComments.required,
                }" rows="5" @blur="SetDirty()" @focus="ResetDirty()">
            </b-textarea>
            <div class="error-message-format required-field" v-if="!$v.RejectionComments.required && $v.RejectionComments.$dirty">
                Required field
            </div>
            <div class="text-right" style="width: 100%; padding-top: 10px">
                <button v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-outline-primary"
                    @click="Reject()">
                    Reject
                </button>
                <button v-else class="btn custom-button btn-outline-primary" disabled="disabled"
                    title="The request is already approved or rejected.">
                    Reject
                </button>
            </div>
        </div>
        <b-modal v-model="ShowRejectModal" :no-close-on-backdrop="true" scrollable title="Add Product">
            <template v-slot:modal-header="{ close }">
                <h5 class="modal-title">{{ ModalTitle }}</h5>
                <button class="close" @click="CancelReject()"></button>
            </template>
            <div class="
                  d-block
                  row
                  col-lg-6 col-md-8
                  offset-lg-2 offset-lg-3
                  primary-content
                ">
                <b-row>
                    <b-col>
                        <label>Rejection Comments</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-textarea v-model="RejectionComments" rows="5"> </b-textarea>
                    </b-col>
                </b-row>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div class="text-right">
                    <button class="btn btn-link text-uppercase" @click="CancelAdd()">
                        Cancel
                    </button>
                    <button :disabled="$v.$invalid" class="text-uppercase btn custom-button btn-primary" @click="Reject()">
                        Reject
                    </button>
                </div>
            </template>
        </b-modal>
        <b-modal v-model="VendorConfirmPaymentInfo" title="Did the vendor confirm the payment information?" hide-footer :no-close-on-backdrop="true">
            <div class="px-4">
                <p>
                    Must verbally confirm payment info before completing request.
                </p>
            </div>
        </b-modal>
        <b-modal v-model="ACHBankingSheet" title="Did the vendor provide an ACH/Wire info sheet if paying by ACH/Wire?" hide-footer :no-close-on-backdrop="true">
            <div class="px-4">
                <p>
                    Vendor will be paid by check at address on W9.
                </p>
            </div>
        </b-modal>
        <b-modal v-model="showModal" class="history-model" title="Attempt History" hide-footer :no-close-on-backdrop="true">
            <AttemptHistory :id="VendorValidation.ID"></AttemptHistory>
        </b-modal>
    </div>
</template>

<script>
import AttemptHistory from "../../Common/AttemptHistory";
import { mapGetters } from "vuex";
import { requiredIf, minLength, helpers } from "vuelidate/lib/validators";
import store from "@/store";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { TheMask } from 'vue-the-mask'
import * as status from "../../../utilities/VendorWorkflowStates";
const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)
export default {
    name: "VendorValidationDetails",
    components: {
        AttemptHistory: AttemptHistory,
        vueDropzone: vue2Dropzone,
        TheMask
    },
    validations: {
        RejectionComments: {
            required: requiredIf(function () {
                return this.RejectComments == true;
            }),
        },
        VendorValidation: {
            DateTime: {
                required: requiredIf(function () {
                    return this.RejectComments != true  || this.RejectComments == undefined;
                }),
            },
            MissingVendorDocs: {
                required: requiredIf(function () {
                    return (this.VendorPending == true && this.RejectComments != true) || this.RejectComments == undefined;
                }),
            },
            ReachingMethod: {
                required: requiredIf(function () {
                    return this.RejectComments != true || this.RejectComments == undefined;
                }),
            },

            ConfirmPaymentInfo: {
                required: requiredIf(function () {
                    return this.RejectComments != true || this.RejectComments == undefined;
                }),
            },

            VendorACHInfo: {
                required: requiredIf(function () {
                    return this.RejectComments != true || this.RejectComments == undefined;
                }),
            },
            VendorContactName: {
                required: false
            },
            VendorTitle: {
                required: requiredIf(function () {
                    return this.RejectComments != true || this.RejectComments == undefined;
                }),
            },
            VendorPhoneNumber: {
                required: requiredIf(function () {
                    return this.RejectComments != true || this.RejectComments == undefined;
                }),
                minLength: minLength(10)
            },
            VendorEmail: {
                required: requiredIf(function () {
                    return this.RejectComments != true || this.RejectComments == undefined;
                }),
                email: EmailWithoutCase
            },
            AttachmentFiles: {
                minLength: minLength(1),
                required: requiredIf(function () {
                    return (this.VendorPending == false && this.RejectComments != true) || this.RejectComments == undefined
                })
            },
            ValidationComments: {
                required: requiredIf(function () {
                    return this.RejectComments != true || this.RejectComments == undefined;
                }),
            },
        },
    },
    data() {
        return {
            formdata: {
                requiredaccountingapproval: null,
            },
            ShowMDAApproval: false,
            IsResubmit: false,
            MediaFormType:'',
            showModal: false,
            historyIcon: require("@/assets/Icons/History.svg"),
            fields: [
                    { key: 'AttemptCount', label: 'Attempt Count' },
                    { key: 'DateOfVendorContact', label: 'Date Of Vendor Contact' },
                    { key: 'MethodforReachingOut', label: 'Method for Reaching Out' },
                    { key: 'Comments', label: 'Comments' },
                    { key: 'VendorValidatorEmailId', label: 'Validator Email Id' },
                ],
            data: {
                rows: [],
            },
            VendorConfirmPaymentInfo: false,
            ACHBankingSheet: false,
            AttemptModel: false,
            ShowHistoryIcon: false,
            dropzoneOptions: {
                closeFilterIcon: require("@/assets/Icons/add-client.svg"),
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/ProfileUnlock/ProfileUpload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert('Maximum File Upload limit is 5!');
                        }
                    });
                },
            },
            VendorPending: true,
            VendorComplete: false,
            MissingDocs: [{
                "label": "Lexis Nexis",
                "value": "Lexis Nexis"
            },
            {
                "label": "D&B",
                "value": "D&B"
            },
            {
                "label": "IRS TIN",
                "value": "IRS TIN"
            },
            {
                "label": "Others",
                "value": "Others"
            }
            ],
            ReachingOptions: [{
                label: "Email",
                value: "Email",
            },
            {
                label: "Phone",
                value: "Phone",
            },
            ],
            ShowPendingorCompletediv: false,
            Error: false,
            ShowRejectModal: false,
            RejectionComments:"",
            HideFooterButtons: true,
            RejectComments: false,
            AgencyList: [],
            myOptions: {
                layout: {
                    color: "black",
                    backgroundColor: "lightgray",
                    selectedColor: "white",
                    selectedBackgroundColor: "green",
                    borderColor: "black",
                    fontFamily: "Arial",
                    fontWeight: "normal",
                    fontWeightSelected: "bold",
                    squareCorners: false,
                    noBorder: false,
                },
                size: {
                    fontSize: 14,
                    height: 2,
                    width: 10,
                },
                items: {
                    delay: 0.4,
                    preSelected: "Pending",
                    disabled: false,
                    labels: [{
                        name: "Pending",
                        color: "white",
                        backgroundColor: "#007bff",
                    },
                    {
                        name: "Complete",
                        color: "white",
                        backgroundColor: "#00a1d2",
                    },
                    ],
                },
            },
        };
    },
    props: {
        Request: {
            required: false,
            type: Object,
            default() {
                return {}
            }
        },
        ID: {
            type: Number,
            required: false,
        },
        VendorFormType: {
            type: String,
        },
    },
    computed: {
        ...mapGetters("vendorValidation", {
            VendorValidation: "VendorValidation",
        }),
        RequestedCode() {
            return this.Request ? this.Request.VendorBusinessName : "";
        },
        ModalTitle() {
            return `Reject Request ${this.Request.VendorBusinessName}`;
        },
        IsApproveOrRejectEnabled() {
            return this.Request && (this.Request.Status == status.VendorValidation || this.Request.Status == status.VendorValidationRejected);
        },
        IsDataLoaded() {
            return this.Request && this.Request.VendorBusinessName ? true : false;
        },
    },
    created() {
        if(this.VendorFormType == 'Media') {
            this.MediaFormType = 'mediavendor'
        }
        if(this.VendorFormType == 'Production Expense') {
            this.MediaFormType = 'prodexpvendor'
        }
        if(this.VendorFormType == 'Amend Media') {
            this.MediaFormType = 'vendormediaamend'
        }
        if(this.VendorFormType == 'Amend Production Expense') {
            this.MediaFormType = 'prodexpamend'
        }
        this.LoadVendorValidationFormData()
    },
  watch: {
    "VendorValidation.Files": function (val) {
        if(val) {
            this.myOptions.items.preSelected = "Complete";
            this.VendorPending = false;
            this.VendorComplete = true;
            this.IsResubmit = true;
            this.ManuallyUploadFilesAttachments(this.VendorValidation.AttachmentFiles);
            this.$set(this.VendorValidation, "AttachmentFiles", this.VendorValidation.Files);
        }
    },
    "Request.Status": function(val) {
            if(val == "Vendor Validation Approval" || val == "Pending Vendor Response" || val == "Vendor Validation" || val == "Vendor Validation Rejected" || val == "Director Approval" || val == "Finance Director Approval") {
                this.ShowMDAApproval = false;
                this.ShowPendingorCompletediv = true;
            }
            else {
                this.ShowMDAApproval = true;
                this.ShowPendingorCompletediv = false;
            }
        },
    },
    methods: {
        GetData() {
            return {
                ID: parseInt(this.ID),
                Comments: this.RejectionComments,
                ActiveTaskID: this.Request.ActiveTaskID,
                ProjectID: this.Request.OmniflowProjectID,
                ProcessID: this.Request.OmniflowProcessID,
                AccountingApprovalRequired: this.formdata.requiredaccountingapproval,
            };
        },
        Approve() {
        this.ShowRejectModal = false;
        this.$store
            .dispatch(`${this.MediaFormType}/ApproveProdExpenseRequestLevel3`, this.GetData())
            .then(() => {
            store.dispatch(
                "progress/setSuccessMessage",
                `The request ${this.Request.VendorBusinessName} has been approved`
            );
            this.$router.push("/VendorRequests");
            })
            .catch((ex) => {
            if (ex.response) {
                this.SetErrorMessage(
                "Error while approving request",
                ex.response.data
                );
            } else {
                this.SetErrorMessage("Error while approving request", ex);
            }
            });
        },
        ShowAttemptHistory() {
            this.showModal = true;
        },
        LoadVendorValidationFormData() {
            this.$store.commit('vendorValidation/clearVendorValidationData');
            this.$store
                .dispatch("vendorValidation/GetVendorValidationDetails", {
                        id : parseInt(this.ID),
                        vendorType:this.VendorFormType,
                    })
                .then((res) => {
                    if(res != null) {
                        this.VendorValidationRequest = res.data;
                        if(this.VendorValidationRequest != null && this.VendorValidationRequest != "") {
                            this.$store.commit("vendorValidation/loadVendorValidationData",this.VendorValidationRequest)
                        }
                    }
                })
                .catch((err) => {
                    this.Error = true;
                    alert(err);
                });
        },
        mountedAttachmentDropzone() {
            this.ManuallyUploadFilesAttachments(this.VendorValidation.AttachmentFiles);
            this.$set(this.VendorValidation, "AttachmentFiles", this.GetIDFilesObject());
        },
        ManuallyUploadFilesAttachments(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded) => {
                    let file = {
                        size: fileUploaded.Length,
                        name: fileUploaded.Name,
                        type: fileUploaded.Extension,
                    };
                    var url = fileUploaded.FullName;
                    this.$refs.dropzoneAttachment.manuallyAddFile(file, url);
                });

            }
        },
        AttachmentUploadedToDropzone() {
            this.$set(this.VendorValidation, "AttachmentFiles", this.GetIDFilesObject());
        },
        AttachmentRemovedFromDropzone() {
            this.$set(this.VendorValidation, "AttachmentFiles", this.GetIDFilesObject());
        },
        GetIDFilesObject() {
            let files = [];
            if (this.$refs.dropzoneAttachment.dropzone.files && this.$refs.dropzoneAttachment.dropzone.files.length > 0) {
                let filesExists = this.$refs.dropzoneAttachment.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        GetFilesObjectSource() {
            let files = [];
            if (typeof this.$refs.dropzoneAttachment != "undefined" &&
                typeof this.$refs.dropzoneAttachment.dropzone != "undefined" &&
                this.$refs.dropzoneAttachment.dropzone.files.length > 0) {
                let filesExists = this.$refs.dropzoneAttachment.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name, file: filesExists[i]
                    });
                }
            }

            return files;
        },
        ShowPaymentInfo() {
            if (this.VendorValidation.ConfirmPaymentInfo == 'false') {
                this.VendorConfirmPaymentInfo = true;
            }
        },
        ShowACHwireInfo() {
            if (this.VendorValidation.VendorACHInfo == 'false') {
                this.ACHBankingSheet = true;
            }
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details">
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        GetPendingorCompleteFormData() {
            return {
                ID: parseInt(this.ID),
                RejectionComments: this.RejectionComments,
                ActiveTaskID: this.Request.ActiveTaskID,
                ProjectID: this.Request.OmniflowProjectID,
                ProcessID: this.Request.OmniflowProcessID,
                ValidationComments:this.VendorValidation.ValidationComments,
                VendorType: this.VendorFormType,
                VendorValidationType: this.VendorPending == true ? 'Pending' : 'Complete',
            };
        },
        PrepareVendorValidationFormData() {
                let vendorInfo = {};
                const confirmPaymentInfo = this.VendorValidation.ConfirmPaymentInfo  == "true" ? true : false;
                const vendorACHInfo = this.VendorValidation.VendorACHInfo  == "true" ? true : false;
                vendorInfo.RejectionComments = this.RejectionComments;
                vendorInfo.VendorId = parseInt(this.ID);
                vendorInfo.VendorType = this.VendorFormType;
                vendorInfo.VendorValidationType = this.VendorPending ? 'Pending' : 'Complete';
                vendorInfo.DateTime = this.VendorValidation.DateTime;
                vendorInfo.ReachingMethod = this.VendorValidation.ReachingMethod.toString();
                vendorInfo.AttachmentFiles = this.VendorValidation.AttachmentFiles;
                vendorInfo.MissingVendorDocs = this.VendorValidation.MissingVendorDocs.toString();
                vendorInfo.VendorContactName = this.VendorValidation.VendorContactName;
                vendorInfo.VendorTitle = this.VendorValidation.VendorTitle;
                vendorInfo.VendorPhoneNumber = this.VendorValidation.VendorPhoneNumber;
                vendorInfo.VendorEmail = this.VendorValidation.VendorEmail;
                vendorInfo.AttemptCounter = this.VendorValidation.AttemptCounter;
                vendorInfo.ConfirmPaymentInfo = confirmPaymentInfo;
                vendorInfo.VendorACHInfo = vendorACHInfo;
                vendorInfo.ValidationComments = this.VendorValidation.ValidationComments;
                vendorInfo.ID = this.VendorValidation.ID;
                if (this.IsResubmit == true) {
                    vendorInfo.Files = [];
                    this.GetFilesCategory().forEach(name => {
                        this.AttachUpdatedFiles(vendorInfo.Files, name);
                    });
                }
                return vendorInfo;
        },
        AttachFiles(formData, fileCategory) {
            let files = this.VendorValidation[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append(`${fileCategory}[${i}]`, file.file || file);
            }
        },
        AttachUpdatedFiles(filesArray, fileCategory) {
            let files = this.VendorValidation[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                filesArray.push({ Category: fileCategory, Name: file.Name || file.name })
            }
        },
        GetFilesCategory() {
            return ["AttachmentFiles"];
        },
        updateMap(event) {
            if (event != null && event == "Pending") {
                this.VendorPending = true;
                this.VendorComplete = false;
            } else {
                this.VendorPending = false;
                this.VendorComplete = true;
            }
        },
        SetDirty() {
            this.$v.RejectionComments.$touch();
        },
        ResetDirty() {
            this.$v.RejectionComments.$reset();
        },
        SetErrorMessage(message, ex) {
            store.dispatch(
                "progress/setErrorMessage",
                `${message} ${this.RequestedCode}. The error is ${ex}`
            );
        },
        GetVendorValidationFormData(){
            let data = this.PrepareVendorValidationFormData();
            let formData = new FormData();
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });
            formData.append('request', JSON.stringify(data));
            return formData;
        },
        SaveVendorValidation(){
            this.$store
            .dispatch("vendorValidation/SaveVendorValidation", this.GetVendorValidationFormData())
            .then(() => {
                    this.PendingVendorApproveorComplete();
            })
            .catch((ex) => {
                if (ex.response) {
                    this.SetErrorMessage(
                        "Error while submitting request",
                        ex.response.data
                    );
                } else {
                    this.SetErrorMessage("Error while submitting request", ex);
                }
            });
        },
        UpdateVendorValidation(){
            this.$store.dispatch("vendorValidation/UpdateVendorValidation", {
                id: parseInt(this.ID),
                data: this.GetVendorValidationFormData()
            })
            .then(() => {
                    this.PendingVendorApproveorComplete();
            })
            .catch((ex) => {
                if (ex.response) {
                    this.SetErrorMessage(
                        "Error while submitting request",
                        ex.response.data
                    );
                } else {
                    this.SetErrorMessage("Error while submitting request", ex);
                }
            });
        },
        PendingVendorApproveorComplete(){
            this.$store
            .dispatch(`${this.MediaFormType}/PendingVendorApproveorComplete`, this.GetPendingorCompleteFormData())
            .then(() => {
                store.dispatch(
                    "progress/setSuccessMessage",
                    `The request has been submitted successfully`
                );
                this.$router.push("/VendorRequests");
            })
            .catch((ex) => {
                 if (ex.response) {
                    this.SetErrorMessage(
                        "Error while submitting request",
                        ex.response.data
                    );
                } else {
                    this.SetErrorMessage("Error while submitting request", ex);
                }
            })
        },
        SubmitVendorValidation() {
            this.ShowRejectModal = false;
            this.RejectComments = false;
            if (!this.$v.$invalid) {
                if(this.VendorPending) {
                    if(this.VendorValidationRequest==null || !this.VendorValidationRequest) {
                        this.VendorValidation.AttemptCounter ++;
                        this.SaveVendorValidation();
                    }
                    else {
                        this.VendorValidation.AttemptCounter = this.VendorValidationRequest.AttemptCounter;
                        this.VendorValidation.AttemptCounter ++;
                        this.UpdateVendorValidation();
                    }
                }
                else if(this.VendorComplete) {
                    if(this.VendorValidationRequest==null || !this.VendorValidationRequest) {
                        this.SaveVendorValidation();
                    }
                    else {
                        this.VendorValidation.AttemptCounter = this.VendorValidationRequest.AttemptCounter;
                        this.UpdateVendorValidation();
                    }
                }
                this.$store.commit('vendorValidation/clearVendorValidationData');
            }
        },
        CancelReject() {
            this.ShowRejectModal = false;
        },
        Reject() {
            this.ShowRejectModal = false;
            this.RejectComments = true;
            this.$v.RejectionComments.$touch();
            if (!this.$v.$invalid) {
                this.$store
                    .dispatch(`${this.MediaFormType}/RejectVendorValidation`, this.GetData())
                    .then(() => {
                        store.dispatch(
                            "progress/setSuccessMessage",
                            `Request ${this.Request.VendorBusinessName} has been rejected`
                        );
                        this.$router.push("/VendorRequests");
                    })
                    .catch((ex) => {
                        if (ex.response) {
                            window.console.log(`error test is ${JSON.stringify(ex.response)}`)
                            this.SetErrorMessage(
                                "Error while rejecting request",
                                ex.response.data
                            );
                        } else {
                            this.SetErrorMessage("Error while rejecting request", ex);
                        }
                    });
            }
        },
    },
};
</script>

<style >
table.b-table tbody tr td {
    line-height: 2;
}
.modal-dialog {
    max-width: calc(100% - 38.333%) !important;
}
.modal-title {
    font-weight: bold !important;
}
.tab-area .jumbotron {
    border: 1px solid #d3d3d3;
}
.attempt-history {
    position:relative;
}
.attempt-history svg {
    width: 27px;
    height: 27px;
}
.attempt-history span {
    position: relative;
    margin: 0;
    left: -2px;
    top: -1px;
}
.attempt-history .btn {
    position: absolute;
    right: 0px;
    bottom: auto;
    top: 8px;
    padding: 0;
    left: auto;
    background: transparent !important;
    border-color: transparent !important;
    height: 35px !important;
}
.tab-area .b-form-btn-label-control.form-control>.dropdown-menu {
    transform: scale(0.90) !important;
}

.tab-area .b-calendar .b-calendar-grid-body .col[data-date] .btn {
    min-width: 32px;
    min-height: 32px;
}

.tab-area .b-calendar .b-calendar-inner {
    width: auto !important;
}
.tab-area .jumbotron label.attempts {
    margin: 0;
    position: absolute;
    top: -26px;
    left: 15px;
    right: auto;
    bottom: auto;
    background: #e9ecef;
    padding: 8.5px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 12px 12px 0px 0px;
    font-weight: bold;
    font-size: 12px;
    border-bottom: none !important;
}
.tab-area .m-custom {
    margin: 0 !important;
}

/*
.tab-area .dz-preview.dz-file-preview {
    position: relative;
    bottom: 32px;
} */

.tab-area .rounded {
    border-radius: 10px !important;
}

.tab-area .toggle-area ul.toggle-switch {
    width: calc(100% - 20px) !important;
    margin: 5px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    background: lightgray;
    padding: 22px 8px;
    border-radius: 50px;
}

.tab-area .toggle-area ul.toggle-switch li {
    height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-area ul.toggle-switch li label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    border: none !important;
    background: #ddd;
    font-weight: bold !important;
    letter-spacing: 1px;
}

.tab-area textarea {
    max-height: 75px;
}

.tab-area .reject-section {
    padding-bottom: 10px;
}

.tab-area .rejection-comments-new {
    margin: 0 10px;
}

@media screen and (min-width: 1200px) {
    .tab-area .page-details {
        padding: 1rem 3rem 0px !important;
    }
}

.tab-area .row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.tab-area .row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.tab-area .review-component>div:first-child {
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%) !important;
    border: solid 1px rgba(239, 242, 244, 0.2) !important;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    background-color: #ffffff;
}

.tab-area .customdropzone {
    height: auto !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.tab-area .Drag-and-drop-file-h {
    margin-left: 18px !important;
    margin-top: 14px !important;
    width: 220px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    /* color: #a2a9ad !important; */
}

.tab-area .draganddropspan {
    color: #00a1d2;
}
.tab-area .b-form-btn-label-control.form-control>.dropdown-menu {
    z-index: 999999;
}
</style>
