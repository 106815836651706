<template>
    <b-table :items="data.rows" :fields="fields" striped hover responsive class="all-items">
        <template v-slot:cell(AttemptCounter)="data">
            <div class="text-center">
                {{ data.value }}
            </div>
        </template>
    </b-table>
</template>
<script>
export default {
    data() {
        return {
            fields: [
                    { key: 'AttemptCount', label: 'Attempt Count' },
                    { key: 'DateOfVendorContact', label: 'Date Of Vendor Contact' },
                    { key: 'MethodforReachingOut', label: 'Method for Reaching Out' },
                    { key: 'Comments', label: 'Comments' },
                    { key: 'VendorValidatorEmailId', label: 'Validator Email Id' },
                ],
            data: {
                rows: [],
            },
        }
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
    },
    mounted() {
        this.loadAttemptHistory()
    },
methods: {
    loadAttemptHistory() {
            this.data.rows = [];
                this.$store
                    .dispatch("vendorValidation/GetAttemptHistory", this.id)
                    .then((res) => {
                        if(res.data.find(el => el.AttemptCount > 1)) {
                            this.ShowHistoryIcon = true;
                        }
                        this.data.rows = res.data.map(el => ({
                            AttemptCount:el.AttemptCount,
                            DateOfVendorContact: new Date(el.DateOfVendorContact).toLocaleDateString(),
                            MethodforReachingOut:el.MethodforReachingOut,
                            Comments:el.Comments,
                            VendorValidatorEmailId:el.VendorValidatorEmailId,
                        }))
                    })
                    .catch((err) => {
                        this.Error = true;
                        alert(err);
                    });
        },
}
}
</script>
